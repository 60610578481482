import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'


// components

import {Link, graphql} from "gatsby"

const Tags=({pageContext,data})=>{
  const {tag} = pageContext
  const {edges, totalCount} = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

return (
  <Layout>
  <BodyClassName className="tags">
  <PageTransition>
   <article className="article">
   <div className="content">
      <header className="articleHeader">
      <h1>{tag}</h1>
      <p className="no-margin">{tagHeader}</p>
     </header>
     <div className="row">
       {edges.map(({ node }) => {
         const { path, title, description, thumb } = node.frontmatter
         return (
               <Link
                 to={path}
                 key={path}
                 className="col-xs-12 col-sm-4 col-md-3 grow"
                 style={{"padding":"10px"}}
               >
               <div className="postcard" >
                 <div className="cardthumb">
                 <img alt="" src={thumb.childImageSharp.fluid.src}/>
                 </div>
                 <div className="cardtitle">
                 <h3 className="no-margin">
                     {title}
                 </h3>
                 <p className="opacity-half description line-clamp-3">
                    {description}
                 </p>
                 </div>
               </div>
               </Link>
             )
           })}
       </div>


     <p><Link to="/tags" className="link">All tags</Link></p>
     </div>
   </article>
   </PageTransition>
   </BodyClassName>
   </Layout>
 )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }} }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            path
            published
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
